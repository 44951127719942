/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-paper': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 0a2 2 0 00-2 2v1.133l-.941.502A2 2 0 000 5.4V14a2 2 0 002 2h12a2 2 0 002-2V5.4a2 2 0 00-1.059-1.765L14 3.133V2a2 2 0 00-2-2zm10 4.267l.47.25A1 1 0 0115 5.4v.817l-1 .6zm-1 3.15l-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 011-1h8a1 1 0 011 1zm-11-.6l-1-.6V5.4a1 1 0 01.53-.882L2 4.267zm13 .566v5.734l-4.778-2.867zm-.035 6.88A1 1 0 0114 15H2a1 1 0 01-.965-.738L8 10.083zM1 13.116V7.383l4.778 2.867L1 13.117z"/>',
    },
});
